import React, { useEffect } from 'react'

import { About, Footer, Header, Skills, Testimonial, Work } from './container'
import { Navbar } from './components'
import './App.css'

import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)


const App = () => {

    const tlCircle = gsap.timeline({
        scrollTrigger: {
            trigger: ".home",
            end:800,
            // markers: true,
            scrub: 2,
            duration: 2
        },
    })
    const tlAbout = gsap.timeline({
        scrollTrigger: {
            trigger: "#about",
            end: 400,
            // markers: true,
            scrub: 1,
            duration: 2
        },
    })


    useEffect(() => {
        tlCircle.to(".circle-1", { y: 800 }, '<')
        tlCircle.to(".circle-2", { y: 800 }, '<0.2')
        tlCircle.to(".circle-3", { y: 800 }, '<0.2')
        tlCircle.to(".circle-4", { y: 800 }, '<0.2')
        tlCircle.to(".circle-5", { y: 800 }, '<0.2')
        tlCircle.to(".circle-6", { y: 800 }, '<0.2')

        tlAbout.from(".about-cards", {y:400})

        
    },[])
    
    return (
        <div className='app'>
            <Navbar />
            <Header />
            <About />
            <Work />
            <Skills />
            {/* <Testimonial /> */}
            <Footer />
        </div>
    )
}

export default App