import React, { useState, useEffect } from 'react'
import { urlFor, client } from '../../client';
import gsap from 'gsap';

import './Work.css'

const Work = () => {
  const [activeFilter, setActiveFilter] = useState('All')
  const [works, setWorks] = useState([])
  const [filterWork, setFilterWork] = useState([])

  const handleWorkFilter = (item) => {
      setActiveFilter(item)
      if(item === 'All') {
        setFilterWork(works)
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)))
      }
      
  }

  useEffect(() => {
    gsap.from(".work-card",{y:100,duration:0.5})
  }, [filterWork])
  

  useEffect(() => {
    const query = '*[_type == "works"]'

    client.fetch(query)
    .then((data) => {
      setWorks(data)
      setFilterWork(data)
    })
  }, [])
  

  return (
    <div className='work-container' id='work'>
      <h2>My Creative Portfolio</h2>

      <div className='app-work-filter'>
        {['Game Development','HTML/CSS/JS','React JS','Desktop App','AI/ML','Notebook','All'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <div className='work-card-container'>
        {filterWork.map((work,index) => (
          <div className='work-card'>
            <div>
              <img width='300px' height='180px' src={urlFor(work.imgUrl)} alt={work.name} />
              <div>
                <h2><span>{index+1}</span> {work.title}</h2>
                { work.projectLink == null ? '' : <a href={work.projectLink} target='_blank'>Project</a>}
                { work.codeLink == null ? '' : <a href={work.codeLink} target='_blank'>Code</a>}
                <p>{work.description}</p>
                
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Work