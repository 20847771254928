import React from 'react'
import { HiHome, FaUserAlt, MdWork, AiFillCode, BsFillChatLeftDotsFill, ImGithub, FaLinkedin } from "react-icons/all"


import './Navbar.css'

const Navbar = () => {
  return (
    <nav>
      <div className='nav-name'>
        {/* <img src={images.logo} alt='logo' /> */}
        <h1 className='vertical-text'>
          <span>R</span>
          <span>O</span>
          <span>U</span>
          <span>N</span>
          <span>A</span>
          <span>K</span>
          <span> </span>
          <span>P</span>
          <span>A</span>
          <span>U</span>
          <span>L</span>
        </h1>
      </div>
      <ul className='nav-link'>
        <li><a href='#home'><HiHome /></a></li>
        <li><a href='#about'><FaUserAlt /></a></li>
        <li><a href='#work'><AiFillCode /></a></li>
        <li><a href='#skills'><MdWork /></a></li>
        <li><a href='#contact'><BsFillChatLeftDotsFill /></a></li>
      </ul>
      <ul className='social-link'>
        <li><a href='https://github.com/Rounak-Paul' target={'_blank'}><ImGithub /></a></li>
        <li><a href='https://www.linkedin.com/in/rounak-paul-603b11227/' target={'_blank'}><FaLinkedin /></a></li>
      </ul>
    </nav>
  )
}

export default Navbar