import React from 'react'


import './Header.css'

const Header = () => {
  return (
    <>
    <div className='circles'>
      <div className='circle-1'></div>
      <div className='circle-2'></div>
      <div className='circle-3'></div>
      <div className='circle-4'></div>
      <div className='circle-5'></div>
      <div className='circle-6'></div>
    </div>
    <div className='home' id='home'>
      
      <div className='home-content'>
        
        <div className='home-name'>
          <h2 className='glitch' data-text='What am I...'>Hello, I am</h2>
          <h1>
            <span>R</span>
            <span>O</span>
            <span>U</span>
            <span>N</span>
            <span>A</span>
            <span>K</span>
            <span> </span>
            <span>P</span>
            <span>A</span>
            <span>U</span>
            <span>L</span>
          </h1>
          <h2>Aspiring Developer!</h2>
        </div>
        <div className='home-btn'>
          <div><a  href='#about'>ABOUT</a></div>
          <div><a  href='#contact'>CONTACT</a></div>
        </div>
      </div>
      <div className='laptop-container'>
        <div class="laptop">
          <div class="content">
            <iframe class="gam"   src="https://i.simmer.io/@Kashless/egg-catcher"></iframe>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}


export default Header