import laptop_blank from '../assets/laptop_blank.png';
import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import earth from '../assets/Earth.svg'


export default {
  laptop_blank,
  email,
  mobile,
  earth

};