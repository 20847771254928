import React, { useState, useEffect } from 'react'

import './About.css'
import { urlFor, client } from '../../client';

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <div className='about-container' id='about'>
      <h2 className="head-text">“Logic will get you from A to Z, imagination will get you everywhere.” <br></br><span> - Albert Einstein </span></h2>

      <div className="about-cards">
        {abouts.map((about, index) => (
          <div
            key={about.title + index}
            className={about.title}
          >
            <img width='300px' height='180px' src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </div>
        ))}
      </div>

    </div>
  )
}

export default About