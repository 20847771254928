import React, { useState, useEffect } from 'react'
import { urlFor, client } from '../../client'
import ReactTooltip from 'react-tooltip'

import './Skills.css'

const Skills = () => {
  const [experience, setExperience] = useState([])
  const [skills, setSkills] = useState([])

  useEffect(() => {
    const query = '*[_type == "experiences"]'
    const skillsQuery = '*[_type == "skills"]'

    client.fetch(query)
    .then((data) => {
      setExperience(data)
    })
    client.fetch(skillsQuery)
    .then((data) => {
      setSkills(data)
    })
  }, [])

  return (
    <div className='skill-container' id='skills'>
      <h2>Skills & Experience</h2>

      <div className='skill-flex'>
        <div className='skill-icon-container'>
          {skills.map((skill) => (
            <div>
              <div className='skill-icon' style={{backgroundColor: skill.bgColor}}>
                <img  width='60px' height='60px' src={urlFor(skill.icon)} alt={skill.name}/>
              </div>
              {/* <p>{skill.name}</p> */}
            </div>
          ))}
        </div>
        <div className='exp-container'>
          {experience.map((experience) => (
            <div key={experience.year}>
              <div className='time-line'>
                <p>{experience.year}</p>
              </div>
              <div>
                {experience.works.map((work) => (
                  <>
                    <div data-tip data-for={work.name} key={work.name}>
                      <h4>{work.name}</h4>
                      <p>{work.company}</p>
                    </div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor='#fff'

                    >
                      {work.desc}
                    </ReactTooltip>
                  </>
                ))}
              </div>
            </div>
            
          ))}
        </div>
      </div>
    </div>
  )
}

export default Skills